import React, { useState, useEffect, useRef } from 'react';

import './printOrder.css';

const embroideryPos = ["", "Ngực", "Eo", "Gấu", "Măng sét", "Giữa lần chân cổ"];
const bodyText = ["None", 'Fitted', 'Semi-fitted', 'Loose fit'];

export class OrderComponentToPrint extends React.PureComponent {


    constructor(props, innerRef) {
        super(props);

        this.state = { checked: false };
    }

    // canvasEl;
    // innerRef = useRef(null);


    componentDidMount() {

    }

    // handleCheckboxOnChange = () =>
    //     this.setState({ checked: !this.state.checked });

    // setRef = (ref) => (this.canvasEl = ref);

    render() {
        const { orderList } = this.props;

        return (
            <>

                {/* <div className="mb5">
                    <button onClick={printDocument} className="btn btn-primary">Print to PDF</button>
                </div> */}

                <div id="divToPrint">

                    {orderList.map((order, ord_idx) => {
                        return (
                            <>
                                <div key={order.order_id}  >
                                    {
                                        order.orderItems.map((orderitem, idx) => {

                                            //console.debug('DEBUG:', orderitem);
                                            return (<>
                                                <div key={order.order_id + idx} className="p-2">
                                                    <div className="mt4" style={{
                                                        //backgroundColor: '#f5f5f5',
                                                        width: '210mm',
                                                        // minHeight: '297mm',
                                                        // width: '1240px',
                                                        // height: '1700px',
                                                        margin: 'auto',
                                                        //marginRight: 'auto',
                                                        // border: "1px solid blue"
                                                    }}>
                                                        <div className='container-md p-6' style={{ border: "1px solid red" }}>
                                                            <div className='row '>
                                                                <div className='col-4 '  >
                                                                    <div className="p-1  text-start"><strong>Order: {order['order_prefix']}{order['order_id']}</strong></div>
                                                                </div>
                                                                <div className='col-4' align="center" >
                                                                    <div className="p-1  "><strong>PB: {order['tailorshop'].short_name}-{order['prod_batch']}</strong></div>
                                                                </div>
                                                                <div className='col-4  '  >
                                                                    <div className="p-1  text-end"><strong>Nhà may: {order['tailorshop'].short_name}</strong></div>
                                                                </div>
                                                            </div>
                                                            <div className='row '>
                                                                <strong>{orderitem['quantity']} x {orderitem['fabric_code']} / Size : Custom	</strong>
                                                            </div>

                                                            <div className='row g-6'>
                                                                <div className='col-6 p-2'  >   {/* Cột trái */}
                                                                    <div className="container-flex px-2">
                                                                        <div className="row lb1">
                                                                            <div className="col-4">Client ID :</div>
                                                                            <div className="col-8">{order['client_id']}</div>
                                                                        </div>
                                                                        <div className="row lb2">
                                                                            <div className="col-4">Tên :</div>
                                                                            <div className="col-8">{order['full_name']}</div>
                                                                        </div>
                                                                        <div className="row lb3" >
                                                                            Thông số kỹ thuật
                                                                        </div>
                                                                        <div className="row" >
                                                                            <div className="col-4 ">{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].pattern}</div>
                                                                            <div className="col-2 "></div>
                                                                            <div className="col-6 text-end">
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern['order_prefix']}{orderitem.data.checkOldPattern['order_id']}&nbsp;
                                                                                    [
                                                                                    {orderitem.data.checkOldPattern['fabric_code']}/{bodyText[parseInt(orderitem.data.checkOldPattern['body_type'])]}
                                                                                    ]
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row lb_top">
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].collar}</div>
                                                                            <div className="col-4 text-start">&nbsp;Cổ</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].collar}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['collar'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['collar']}
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end  lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].chest}</div>
                                                                            <div className="col-4 text-start">&nbsp;Ngực</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].chest}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['chest'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['chest']}
                                                                                </>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end  lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].waist}</div>
                                                                            <div className="col-4 text-start">&nbsp;Eo</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].waist}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['waist'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['waist']}
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end  lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].hip}</div>
                                                                            <div className="col-4 text-start">&nbsp;Gấu</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].hip}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['hip'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['hip']}
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end  lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].shoulder}</div>
                                                                            <div className="col-4 text-start">&nbsp;Vai</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].shoulders}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['shoulders'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['shoulders']}
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end  lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].sleeve}</div>
                                                                            <div className="col-4 text-start">&nbsp;Dài tay</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].sleeve_length}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['sleeve_length'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['sleeve_length']}
                                                                                </>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].upper_arm}</div>
                                                                            <div className="col-4 text-start">&nbsp;Bắp tay</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].upper_arm}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['upper_arm'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['upper_arm']}
                                                                                </>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row lb_mid">
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtPattern'] && orderitem.data['shirtPattern'].wrist}</div>
                                                                            <div className="col-4 text-start">&nbsp;Măng sét</div>
                                                                            <div className="col-2 text-end lb4">&nbsp;{orderitem.data['shirtmeasure'].wrist}</div>
                                                                            <div className="col-4 text-start">&nbsp;
                                                                                {!(orderitem.data.checkOldPattern == null || orderitem.data.checkOldPattern.olddata == null) && <>
                                                                                    {orderitem.data.checkOldPattern.check['wrist'] ? <>&#10003;</> : orderitem.data.checkOldPattern.olddata['wrist']}
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                        {/* <div className="row lb_mid">
                                                                    <div className="col-2 text-end lb4">&nbsp;</div>
                                                                    <div className="col-5 text-start">&nbsp;Dài áo (Business) </div>
                                                                    <div className="col-2 text-end lb4">&nbsp;79</div>
                                                                    <div className="col-3 text-end">&nbsp;</div>
                                                                </div> */}
                                                                        <div className="row lb_bottom">
                                                                            <div className="col-2 text-end lb4">&nbsp;</div>
                                                                            <div className="col-4 text-start">&nbsp;Dài áo (
                                                                                {orderitem.data.untuckedShirt ? <>Casual</> : <>Business</>})

                                                                                {/* untuckedShirt:{orderitem.data['untuckedShirt'] + ""}<br />
                                                                        casual:{orderitem.data['shirtmeasure'].back_length_casual}<br />
                                                                        business:{orderitem.data['shirtmeasure'].back_length_business} */}
                                                                            </div>
                                                                            <div className="col-2 text-end lb4">&nbsp;
                                                                                {orderitem.data.untuckedShirt ? <>{orderitem.data['shirtmeasure'].back_length_casual}</> : <>{orderitem.data['shirtmeasure'].back_length_business}</>}
                                                                            </div>
                                                                            <div className="col-4 text-start">&nbsp;

                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div id="shirtView" style={{ width: "350px", height: "337px", padding: "0", margin: "auto" }}>
                                                                                <div id="shirtBody" style={{
                                                                                    backgroundImage: orderitem.data['body'].imgUrl,
                                                                                    backgroundPosition: "initial", backgroundRepeat: "initial", border: "solid black 1px"
                                                                                }}>
                                                                                    <div id="shirtArms" style={{ background: orderitem.data['sleeve'].imgUrl }}>
                                                                                        <div id="shirtPlackets" style={{ background: orderitem.data['front'].imgUrl }} >

                                                                                            <div id="shirtPocketFlaps">
                                                                                                <div id="shirtButtons" style={{ background: "none" }}>
                                                                                                    <div id="shirtCollars" style={{ background: orderitem.data['collar'].imgUrl }}>
                                                                                                        <div id="shirtTopButton" >
                                                                                                            <div id="shirtEpaulettes" style={{ background: orderitem.data['epaulettes'].imgUrl }}>
                                                                                                                <div id="shirtPlacketButton">
                                                                                                                    <div id="shirtPockets" style={{ background: orderitem.data['pockets'].imgUrl }} >
                                                                                                                        <div id="shirtFlaps" style={{ background: orderitem.data['flap'].imgUrl }}>
                                                                                                                            <div id="shirtCuffs" style={{ background: orderitem.data['cuffssleeve'].imgUrl, width: "350px", height: "337px" }}>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="row">
                                                                    <div id="shirtView" style={{ width: "350px", height: "337px", padding: "0", margin: "auto" }}>
                                                                        <div id="bshirtBody" style={{
                                                                            backgroundImage: orderitem.data['body'].imgBackUrl,
                                                                            backgroundPosition: "initial",
                                                                            backgroundRepeat: "initial",
                                                                            border: "solid black 1px"
                                                                        }}>
                                                                            <div id="bshirtArms" style={{ background: orderitem.data['sleeve'].imgBackUrl }}>
                                                                                <div id="bshirtPlackets" style={{ background: orderitem.data['front'].imgBackUrl }} >

                                                                                    <div id="bshirtPocketFlaps">
                                                                                        <div id="bshirtButtons" style={{ background: "none" }}>
                                                                                            <div id="bshirtCollars" style={{ background: orderitem.data['collar'].imgBackUrl }}>
                                                                                                <div id="bshirtTopButton" >
                                                                                                    <div id="bshirtEpaulettes" style={{ background: orderitem.data['epaulettes'].imgBackUrl }}>
                                                                                                        <div id="bshirtFlaps" style={{ background: orderitem.data['splitYoke'] ? 'url(./images_shirt/back_yoke_split.png)' : '' }}>
                                                                                                            <div id="bshirtPlacketButton">
                                                                                                                <div id="bbackPleats" style={{ background: orderitem.data['backPleats'].imgBackUrl }} >
                                                                                                                    <div id="bshirtCuffs" style={{ background: orderitem.data['cuffssleeve'].imgBackUrl, width: "350px", height: "337px" }}>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                    </div>
                                                                </div>
                                                                <div className='col-6 p-2  '  >   {/* Cột phải */}
                                                                    <div className="container-flex px-2 ">
                                                                        <div className="row rb_top">
                                                                            <div className="col-5 text-start">&nbsp;Kiểu thân</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['body'].name}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Vải</div>
                                                                            <div className="col-7 text-start">&nbsp;<strong>{orderitem.fabric_code}</strong></div>
                                                                        </div>
                                                                        <div className="row rb_bottom">
                                                                            <div className="col-5 text-start">&nbsp;Giới tính</div>
                                                                            <div className="col-7 text-start">&nbsp;Nam</div>
                                                                        </div>
                                                                        <div className="row rb_top">
                                                                            <div className="col-5 text-start">&nbsp;Cổ</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['collar'].name}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Cá cổ rời</div>
                                                                            <div className="col-7 text-start">&nbsp;{(orderitem.data['removeableStay'] === true) && <>Có</>}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Lé cổ</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['pipingColor'].fabric}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Phối chân cổ</div>
                                                                            <div className="col-7 text-start">&nbsp;{(orderitem.data['insCollarFabric'].fabric_code === orderitem.fabric_code) ?
                                                                                <>&nbsp;</> : <>{orderitem.data['insCollarFabric'].fabric_code}</>}</div>
                                                                        </div>

                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Phối măng sét</div>
                                                                            <div className="col-7 text-start">&nbsp;{(orderitem.data['insCuffFabric'].fabric_code === orderitem.fabric_code) ?
                                                                                <>&nbsp;</> : <>{orderitem.data['insCuffFabric'].fabric_code}</>}</div>
                                                                        </div>

                                                                        {orderitem.data['whitecollarcuff'].name_vn && <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Phối toàn bộ</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['whitecollarcuff'].name_vn}</div>
                                                                        </div>
                                                                        }

                                                                        {orderitem.data['leftWristPlus'] && <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Cộng măng sét</div>
                                                                            <div className="col-7 text-start">&nbsp; MS trái + 1cm</div>
                                                                        </div>
                                                                        }
                                                                        {orderitem.data['rightWristPlus'] && <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Cộng măng sét</div>
                                                                            <div className="col-7 text-start">&nbsp; MS phải + 1cm</div>
                                                                        </div>
                                                                        }
                                                                        <div className="row rb_bottom">
                                                                            <div className="col-5 text-start">&nbsp;Măng sét</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['cuffssleeve'].name}</div>
                                                                        </div>


                                                                        <div className="row rb_top">
                                                                            <div className="col-5 text-start">&nbsp;Dài tay</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['sleeve'].name_vn}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Bổ cầu vai</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['splitYoke'] && <>Có</>}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Ly cầu vai</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['backPleats'].name_vn}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Cá vai</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['epaulettes'].name === "Yes" && <>Có</>}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Nẹp ngực</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['front'].name_vn}</div>
                                                                        </div>
                                                                        <div className="row rb_mid">
                                                                            <div className="col-5 text-start">&nbsp;Sườn</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['hemSide'].name_vn}</div>
                                                                        </div>
                                                                        <div className="row rb_bottom">
                                                                            <div className="col-5 text-start">&nbsp;Kiểu dài áo</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data.untuckedShirt ? <>Casual</> : <>Business</>} </div>
                                                                        </div>

                                                                        <div className="row rb_top">
                                                                            <div className="col-5 text-start">&nbsp;Túi ngực</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['pockets'].name_vn}</div>
                                                                        </div>
                                                                        <div className="row rb_bottom">
                                                                            <div className="col-5 text-start">&nbsp;Nắp túi</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['flap'].name_vn}</div>
                                                                        </div>
                                                                        <div className="row rb_top">
                                                                            <div className="col-5 text-start">&nbsp;Thùa đính</div>
                                                                            <div className="col-7 text-start">&nbsp;
                                                                                {orderitem.data['buttonHoleColor'].btnhole_code === 'none' ?
                                                                                    <>Giống vải</> : <>{orderitem.data['buttonHoleColor'].name}
                                                                                    </>}</div>

                                                                        </div>
                                                                        <div className="row rb_bottom">
                                                                            <div className="col-5 text-start">&nbsp;Cúc</div>
                                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['button'].name_vn}</div>
                                                                        </div>

                                                                        {orderitem.data['embroidery'].id === "2" && <>
                                                                            <div className="row rb_top">
                                                                                <div className="col-5 text-start">&nbsp;Tên thêu</div>
                                                                                <div className="col-7 text-start">&nbsp;{orderitem.data['embroideryText']}</div>
                                                                            </div>
                                                                            <div className="row rb_mid">
                                                                                <div className="col-5 text-start">&nbsp;Vị trí thêu</div>
                                                                                <div className="col-7 text-start">&nbsp;{orderitem.data['embroideryPosition'] && embroideryPos[parseInt(orderitem.data['embroideryPosition'])]}</div>
                                                                            </div>
                                                                            <div className="row rb_mid">
                                                                                <div className="col-5 text-start">&nbsp;Font</div>
                                                                                <div className="col-7 text-start">&nbsp;{orderitem.data['embroideryFont'] === "1" ? "Modern (1) " : "Classic (2)"}</div>
                                                                            </div>
                                                                            <div className="row rb_bottom">
                                                                                <div className="col-5 text-start">&nbsp;Màu</div>
                                                                                <div className="col-7 text-start">&nbsp;
                                                                                    {orderitem.data['embroideryColor'] && <>
                                                                                        {orderitem.data['embroideryColor'].embcolor_code === 'none' ? <>Giống vải</> :
                                                                                            <>{orderitem.data['embroideryColor'].name + " (" + orderitem.data['embroideryColor'].embcolor_code + ")"}</>}

                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="container-flex g-1 px-2 lb ">
                                                                    <strong>Ghi chú:</strong>
                                                                    <div>
                                                                        {orderitem['notes']}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='row text-end'>
                                                                <div className="container-flex g-1 px-2"> (<i>Order Item ID: {orderitem.item_id}</i>)
                                                                    Page : {idx + 1} / {order.orderItems.length}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>


                                                </div >
                                                {!((idx === order.orderItems.length - 1) && (ord_idx === orderList.length - 1)) &&
                                                    < div class="pagebreak"> </div >
                                                }

                                            </>)
                                        })
                                    }
                                </div >
                            </>

                        )
                    })}
                </div >
                {/* DEBUG: {JSON.stringify(orderList[0])} */}
            </>
        );
    }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
    // eslint-disable-line max-len
    return <printDocument ref={ref} text={props.text} />;
});
