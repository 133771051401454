import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';

const columns = [
    { field: 'usr_id', headerName: 'ID', width: 90 },
    {
        field: 'usr_name', headerName: 'user name',
        width: 150,
    },
    {
        field: 'first_name',
        headerName: 'First name',
        width: 150,
    },
    {
        field: 'last_name',
        headerName: 'Last name',
        width: 150,
    },
    {
        field: 'full_name',
        headerName: 'Full name',
        width: 150,
    },
    {
        field: 'gender',
        headerName: 'Gender',
        width: 110,
    },
    {
        field: 'email',
        headerName: 'email',
        width: 150,
    },
    {
        field: 'telephone',
        headerName: 'Telephone',
        width: 150,
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 150,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 150,
    },
    {
        field: 'state',
        headerName: 'State',
        width: 150,
    }, {
        field: 'create_date',
        headerName: 'Created at',
        width: 150,
    }, {
        field: 'update_date',
        headerName: 'Updated on',
        width: 150,
    },

];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

export default function UsersMan(props) {
    const [searchText, setSearchText] = useState("");

    const [gridData, setGridData] = useState([]);

    const [tailorShops, setTailorShops] = useState([]);
    const [tailorshop, setTailorShop] = useState({ tailorshop_id: "1", short_name: "YB" });

    const auth = useAuth();

    const navigate = useNavigate();

    const addId = (collection, idName) => {
        return collection.map(item => {
            // console.log('item:', {
            //     ...item,
            //     id: item[idName],
            // });
            return {
                ...item,
                id: item[idName],
            };
        });
    };

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleRowClick = (params) => {
        console.log('Click Row: ', params.row);
        props.selectClient(params.row, false);
    };

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }

        fetch("/api/searchusers/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({ searchText: searchText }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "usr_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();

    }

    const handleTailorShopSelect = (event) => {

        let ts = tailorShops.find((e) => e.tailorshop_id === event.target.value);

        if (ts) {
            console.log('TS:', ts);
            setTailorShop(ts);

        }
    }

    const handleAddUser = (event) => {
        navigate('/adduser');

    }
    const handleBlockUser = (event) => {
        alert("Block/Unblock user");
    }
    const handleRemoveUser = (event) => {
        alert("Remove user");
    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/tailorshops/", {
            method: "GET",
            headers: getHeaders(auth),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                //getOrderItems(rowData2);
                //console.log(rowData);
                setTailorShops(rowData);

                if (rowData.length >= 1) {
                    setTailorShop(rowData[0]);
                }

            })
            .catch((error) => console.log(error));

        fetch("/api/users", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                const rowData2 = addId(rowData, "usr_id");
                setGridData(rowData2);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-3 row p-0">

                <label className="col-sm-2 col-form-label" htmlFor="tailorshop"> Tailor shop:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <select className="form-control" aria-label="Tailor shop" style={{ height: "55px", width: "450px" }} id="tailorshop"
                        onChange={handleTailorShopSelect}>
                        {/* <option value="0" id="0" defaultValue>All</option> */}

                        {tailorShops.map((shop) => {
                            return (
                                <option value={shop.tailorshop_id} key={shop.tailorshop_id}>{shop.tailorshop_id} - {shop.name} ({shop.short_name})</option>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="mb-3 row p-0">
                <label htmlFor="searchText" className="col-sm-2 col-form-label">Search users:</label>
                <div className="col-sm-10 " style={{ flexFlow: "row", display: "flex" }}>
                    <input type="text" className="form-control" id="searchText" value={searchText} placeholder="Enter email, telephone or name"
                        onChange={changeSearchText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleSearchBtn();
                        }} />
                    <span><button type="button" className="btn btn-success" onClick={handleSearchBtn}><SearchIcon /></button></span>
                </div>

            </div>
            <div className="m-1 row ">
                <div className="col">
                    <button type="submit" className="btn btn-primary mx-2" style={{ height: "50px" }}
                        onClick={handleAddUser}>Add User</button>
                    <button type="submit" className="btn btn-warning mx-2" style={{ height: "50px" }}
                        onClick={handleBlockUser}>Block/Unblock User</button>
                    <button type="submit" className="btn btn-danger mx-2" style={{ height: "50px" }}
                        onClick={handleRemoveUser}>Remove User</button>
                </div>

            </div>

            <DataGrid
                rows={gridData}
                columns={columns}
                checkboxSelection={false}
                disableMultipleRowSelection={true}
                columnGroupingModel={columnGroupingModel}
                density='compact'
                onRowClick={handleRowClick}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
            />
        </div>
    );
}
