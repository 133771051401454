import React, { useState, useEffect } from 'react';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './orderDetail.css';
//import { ClassComponent } from '../printExp_/printOrderComponent';

const embroideryPos = ["", "Ngực", "Eo", "Gấu", "Măng sét", "Giữa lần chân cổ"];

export default function OrderDetails({ orderList, innerRef }) {
    //export const OrderDetails = React.forwardRef((props, ref) => {

    // let orderList = props.orderList;
    /*
        Mật độ điểm ảnh 72 PPI sẽ có kích thước (pixel) là 595 x 842.
    Mật độ điểm ảnh 96 PPI sẽ có kích thước (pixel) là 794 x 1123.
    Mật độ điểm ảnh 150 PPI sẽ có kích thước (pixel) là 1240 x 1754.
    Mật độ điểm ảnh 300 PPI sẽ có kích thước (pixel) là 2480 x 3508.
    */
    const printDocument = () => {

        var doc = new jsPDF("p", "mm", "a4");

        var first = true;
        orderList.map((order) => {
            order.orderItems.map(async (orderitem, idx) => {
                let id = 'pdf' + order.order_id + "_" + idx;
                console.log(id);
                const input = document.getElementById(id);
                await html2canvas(input, {
                    scale: 2,
                    width: 794,
                    height: 1123,
                })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/jpg', 0.3);
                        //const pdf = new jsPDF();
                        //pdf.addImage(imgData, 'JPEG', 5, 5);

                        var width = doc.internal.pageSize.getWidth();
                        var height = doc.internal.pageSize.getHeight();

                        if (!first) {
                            doc.addPage('a4', 'portrait');
                        }
                        first = false;
                        doc.addImage(imgData, 'JPG', 5, 5, width - 10, height - 10);


                    });
            });
        });
        setTimeout(() => {
            doc.save("download.pdf");
        }, 1000);

    }

    return (
        <>
            <div className="mb5">
                <button onClick={printDocument} className="btn btn-primary">Print to PDF</button>
            </div>

            <div ref={innerRef} >
                {orderList.map((order) => {
                    return (

                        <div key={order.order_id} >

                            {order.orderItems.map((orderitem, idx) => {
                                let id = 'pdf' + order.order_id + "_" + idx;
                                return (<>
                                    <br />
                                    <div id={id} className="mt4" style={{
                                        //backgroundColor: '#f5f5f5',
                                        // width: '210mm',
                                        // minHeight: '297mm',
                                        width: '794px',
                                        height: '1123px',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        border: "1px solid blue",
                                        // filter: "gray", /* IE6-9 */
                                        // webkitFilter: "grayscale(1)", /* Google Chrome, Safari 6+ & Opera 15+ */
                                        // filter: "grayscale(1)", /* Microsoft Edge and Firefox 35+ */
                                        fontSize: "13px",
                                    }}>
                                        <div className='container-md p-6' style={{ border: "1px solid red" }}>
                                            <div className='row '>
                                                <div className='col-4 '  >
                                                    <div className="p-1  text-start"><strong>Order ID: {order['order_id']}</strong></div>
                                                </div>
                                                <div className='col-4' align="center" >
                                                    <div className="p-1  "><strong>PB: {order['order_prefix']}-{order['prod_batch']}</strong></div>
                                                </div>
                                                <div className='col-4  '  >
                                                    <div className="p-1  text-end"><strong>Nhà may: {order['short_name']}</strong></div>
                                                </div>
                                            </div>
                                            <div className='row '>
                                                <strong>{order['quantity']} x {order['fabric_code']} / Size : Custom	</strong>
                                            </div>

                                            <div className='row g-6'>
                                                <div className='col-6 p-2'  >   {/* Cột trái */}
                                                    <div className="container-flex px-2">
                                                        <div className="row lb1">
                                                            <div className="col-3">Client ID :</div>
                                                            <div className="col-9">{order['client_id']}</div>
                                                        </div>
                                                        <div className="row lb2">
                                                            <div className="col-3">Tên :</div>
                                                            <div className="col-9">{order['full_name']}</div>
                                                        </div>

                                                        <div className="row" >
                                                            <div className="col-2 lb3 p-1">Ex12-L</div>
                                                            <div className="col-7 lb3 p-1">Thông số kỹ thuật</div>
                                                            <div className="col-3 lb3 p-1">23HNT</div>
                                                        </div>

                                                        <div className="row lb_top">
                                                            <div className="col-2 text-end lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Cổ</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['neck']}</div>
                                                            <div className="col-3 text-end">&nbsp;41</div>
                                                        </div>

                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end  lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Ngực</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['chest']}</div>
                                                            <div className="col-3 text-end">&nbsp;100</div>
                                                        </div>
                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end  lb4">&nbsp;100</div>
                                                            <div className="col-5 text-start">&nbsp;Eo</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['waist']}</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>

                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end  lb4">&nbsp;106</div>
                                                            <div className="col-5 text-start">&nbsp;Gấu</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['hip']}</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>

                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end  lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Vai</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['shoulders']}</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>

                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end  lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Dài tay</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['arm_length']}</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>
                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Bắp tay</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['upper_arm']}</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>
                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end lb4">&nbsp;26</div>
                                                            <div className="col-5 text-start">&nbsp;Măng sét</div>
                                                            <div className="col-2 text-end lb4">&nbsp;{order.data.measurements['wrist']}</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>
                                                        <div className="row lb_mid">
                                                            <div className="col-2 text-end lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Dài áo (Business) </div>
                                                            <div className="col-2 text-end lb4">&nbsp;79</div>
                                                            <div className="col-3 text-end">&nbsp;</div>
                                                        </div>
                                                        <div className="row lb_bottom">
                                                            <div className="col-2 text-end lb4">&nbsp;</div>
                                                            <div className="col-5 text-start">&nbsp;Dài áo (Casual) </div>
                                                            <div className="col-2 text-end lb4">&nbsp;</div>
                                                            <div className="col-3 text-end">&nbsp;80</div>
                                                        </div>

                                                        <div className="row">
                                                            <div id="shirtView" style={{ width: "350px", height: "337px", padding: "0", margin: "auto" }}>
                                                                <div id="shirtBody" style={{
                                                                    backgroundImage: orderitem.data['body'].imgUrl,
                                                                    backgroundPosition: "initial", backgroundRepeat: "initial", border: "solid black 1px"
                                                                }}>
                                                                    <div id="shirtArms" style={{ background: orderitem.data['sleeve'].imgUrl }}>
                                                                        <div id="shirtPlackets" style={{ background: orderitem.data['front'].imgUrl }} >

                                                                            <div id="shirtPocketFlaps">
                                                                                <div id="shirtButtons" style={{ background: "none" }}>
                                                                                    <div id="shirtCollars" style={{ background: orderitem.data['collar'].imgUrl }}>
                                                                                        <div id="shirtTopButton" >
                                                                                            <div id="shirtEpaulettes" style={{ background: orderitem.data['epaulettes'].imgUrl }}>
                                                                                                <div id="shirtPlacketButton">
                                                                                                    <div id="shirtPockets" style={{ background: orderitem.data['pockets'].imgUrl }} >
                                                                                                        <div id="shirtFlaps" style={{ background: orderitem.data['flap'].imgUrl }}>
                                                                                                            <div id="shirtCuffs" style={{ background: orderitem.data['cuffssleeve'].imgUrl, width: "350px", height: "337px" }}>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-6 p-2  '  >   {/* Cột phải */}
                                                    <div className="container-flex px-2 ">
                                                        <div className="row rb_top">
                                                            <div className="col-5 text-start">&nbsp;Kiểu thân</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['body'].name}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Vải</div>
                                                            <div className="col-7 text-start">&nbsp;<strong>{orderitem.fabric_code}</strong></div>
                                                        </div>
                                                        <div className="row rb_bottom">
                                                            <div className="col-5 text-start">&nbsp;Giới tính</div>
                                                            <div className="col-7 text-start">&nbsp;Nam</div>
                                                        </div>
                                                        <div className="row rb_top">
                                                            <div className="col-5 text-start">&nbsp;Cổ</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['collar'].name}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Cá cổ rời</div>
                                                            <div className="col-7 text-start">&nbsp;{(orderitem.data['removeableStay'] === true) && <>Có</>}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Phối chân cổ</div>
                                                            <div className="col-7 text-start">&nbsp;{(orderitem.data['insCollarFabric'].fabric_code === orderitem.fabric_code) ?
                                                                <>&nbsp;</> : <>{orderitem.data['insCollarFabric'].fabric_code}</>}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Màu dây lé cổ</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['splitYoke']}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Phối măng sét</div>
                                                            <div className="col-7 text-start">&nbsp;{(orderitem.data['insCuffFabric'].fabric_code === orderitem.fabric_code) ?
                                                                <>&nbsp;</> : <>{orderitem.data['insCuffFabric'].fabric_code}</>}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Măng sét</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['cuffssleeve'].name}</div>
                                                        </div>

                                                        {orderitem.data['leftWristPlus'] && <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Cộng măng sét</div>
                                                            <div className="col-7 text-start">&nbsp; MS trái + 1cm</div>
                                                        </div>
                                                        }
                                                        {orderitem.data['rightWristPlus'] && <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Cộng măng sét</div>
                                                            <div className="col-7 text-start">&nbsp; MS phải + 1cm</div>
                                                        </div>
                                                        }
                                                        <div className="row rb_bottom">
                                                            <div className="col-5 text-start">&nbsp;Phối toàn bộ</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['whitecollarcuff'].name}</div>
                                                        </div>
                                                        <div className="row rb_top">
                                                            <div className="col-5 text-start">&nbsp;Dài tay</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['sleeve'].name}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Bổ cầu vai</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['splitYoke'] && <>Có</>}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Ly cầu vai</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['backPleats'].name_vn}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Cá vai</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['epaulettes'].name === "Yes" && <>Có</>}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Nẹp ngực</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['front'].name}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Sườn</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['hemSide'].name}</div>
                                                        </div>
                                                        <div className="row rb_bottom">
                                                            <div className="col-5 text-start">&nbsp;Kiểu dài áo</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data.untuckedShirt ? <>Casual</> : <>Business</>} </div>
                                                        </div>

                                                        <div className="row rb_top">
                                                            <div className="col-5 text-start">&nbsp;Túi ngực</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['pockets'].name}</div>
                                                        </div>
                                                        <div className="row rb_bottom">
                                                            <div className="col-5 text-start">&nbsp;Nắp túi</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['flap'].name}</div>
                                                        </div>
                                                        <div className="row rb_top">
                                                            <div className="col-5 text-start">&nbsp;Màu khuyết</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['buttonHoleColor'].name}</div>
                                                        </div>
                                                        <div className="row rb_bottom">
                                                            <div className="col-5 text-start">&nbsp;Cúc</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['button'].button_code}</div>
                                                        </div>
                                                        <div className="row rb_top">
                                                            <div className="col-5 text-start">&nbsp;Tên thêu</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['embroideryText']}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Vị trí thêu</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['embroideryPosition'] && embroideryPos[parseInt(orderitem.data['embroideryPosition'])]}</div>
                                                        </div>
                                                        <div className="row rb_mid">
                                                            <div className="col-5 text-start">&nbsp;Font</div>
                                                            <div className="col-7 text-start">&nbsp;{orderitem.data['embroideryFont']}</div>
                                                        </div>
                                                        <div className="row rb_bottom">
                                                            <div className="col-5 text-start">&nbsp;Màu</div>
                                                            <div className="col-7 text-start">&nbsp;  {orderitem.data['embroideryColor'] &&
                                                                orderitem.data['embroideryColor'].name + " (" + orderitem.data['embroideryColor'].embcolor_code + ")"

                                                            }</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="container-flex g-1 px-2 lb ">
                                                    <strong>Ghi chú:</strong>
                                                </div>

                                            </div>
                                            <div className='row text-end'>
                                                <div className="container-flex g-1 px-2"> (<i>Order Item ID: {orderitem.item_id}</i>)
                                                    Page : {idx + 1} / {order.orderItems.length}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </>)
                            })}
                        </div >
                    );
                })
                }
            </div >

            {/* DEBUG: {JSON.stringify(orderList[0])} */}

        </>
    );
}

// body: body,
// collar: collar,
// cuffssleeve: cuffssleeve,
// front: front,
// pockets: pockets,
// flap: flap,
// epaulettes: epaulettes,
// whitecollarcuff: whiteCollarCuff,
// backPleats: backPleats,
// hemSide: hemSide,
// insideCollCuff: insideCollCuff,
// embroidery: embroidery,
// embroideryText: embroideryText,
// embroideryColor: embroideryColor,
// embroideryFont: embroideryFont,
// embroideryPosition: embroideryPosition,

// removeableStay: removeableStay,
// leftWristPlus: leftWristPlus,
// rightWristPlus: rightWristPlus,

// insCollarFabric: curInsCollarFabric,
// insCuffFabric: curInsCuffFabric,
// button: curButton,
// splitYoke: splitYoke,
